import { For, splitProps } from "solid-js";
import { Toast } from "../toast";
import type { MainProps } from "./_model";
import type { _Toast } from "../toast";
import "./style.css";

export default function (props: MainProps) {
  const [local, others] = splitProps(props, ["alerts"]);
  return (
    <div {...others} class={`toast-group ${others.class ?? ""}`}>
      <For each={local.alerts.value}>
        {(alert: _Toast.Message, index) => {
          const i = index();
          const on_unmount = alert.onUnmount;
          alert.onUnmount = () => {
            if (on_unmount) {
              on_unmount();
            }
            // console.log("removing ", i);
            local.alerts.set((s) => s.filter((a, id) => i !== id));
          };
          return <Toast {...alert} />;
        }}
      </For>
    </div>
  );
}
